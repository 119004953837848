import React from "react";
import "../styles/components/insights.scss";

export const Insights = ({
  text1,
  text2,
  imageURL,
  variant = 1, // Variant = 1, 2
}) => {
  return (
    <div className={`insights-wrapper insights-wrapper--variant-${variant}`}>
      <div className={`insights insights--variant-${variant}`}>
        <img className={`insights__img insights__img--variant-${variant}`} src={imageURL} alt="" />
        <p className={`insights__text-1 insights__text-1--variant-${variant}`}>{text1}</p>
        <p className={`insights__text-2 insights__text-2--variant-${variant}`}>{text2}</p>
      </div>
    </div>
  );
};
