const Config = {
  DEV_MODE: false,
  CLIENT_TYPE: "cpw",
  CLIENT_VERSION: "2024121102",
  // BASE_APP_URL: "http://localhost/",
  // BASE_APP_URL: "https://staging.boomingo.io/",
  BASE_APP_URL: "https://boomingo.io/",

  GOOGLE_ANALYTICS_APP_ID: "G-JQ7RVM231S",

  SUPPORT_PHONE_NUMBER: "+923133332469",
  SUPPORT_PHONE_NUMBER_DASHES: "03-133-332-469",
  // FACEBOOK_ANALYTICS_APP_ID: "431314987524947",
  FACEBOOK_ANALYTICS_APP_ID: "",

  TWITTER_ANALYTICS_APP_ID: "",

  LINKEDIN_ANALYTICS_APP_ID: "",

  DESKTOP_BREAKPOINT: 1920,

  SUPPORT_EMAIL_ADDRESS: "support@boomingo.io",

  ANALYTICS_ENABLED: false,

  /*Why subtract .02px? Browsers don’t currently support range 
      context queries, so we work around the limitations of min- and 
      max- prefixes and viewports with fractional widths (which can 
      occur under certain conditions on high-dpi devices, for instance) 
      by using values with higher precision. */

  TABLET_BREAKPOINT: 991.98,
  MOBILE_BREAKPOINT: 575.98,

  API_END_POINT: "", // Initialized ahead. Defined here for intellisense on Config object.

  APPLE_STORE_LINK: "https://apps.apple.com/us/app/boomingo/id6737771441",

  GOOGLE_PLAY_STORE_LINK: "https://play.google.com/store/apps/details?id=io.boomingo.app",

  BOOMINGO_APP_URL: "https://app.boomingo.io/",
  BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE: "https://app.boomingo.io/?ca=1",
  BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE_AND_PAYMENT_REQUIRED: "https://app.boomingo.io/?ca=1&pr=1",

  CALENDLY_URL: "https://calendly.com/junaidb-pzwk/chow-loyalty-demo",
};

let isProduction = false;
try {
  const regex = /^https:\/\/boomingo\.(io|ai)/;
  if (regex.test(window.location.href) || regex.test(Config.BASE_APP_URL)) {
    isProduction = true;
  }
} catch (error) {
  console.log("Encountered exception: ", error);
}

if (isProduction) {
  Config.ANALYTICS_ENABLED = true; //isProduction;
  // Config.API_END_POINT = "https://app.boomingo.io/api/v1";
  Config.API_END_POINT = "https://roomy.pk/api/v1";
} else {
  Config.API_END_POINT = Config.BASE_APP_URL + "api/v1";
}

export default Config;
