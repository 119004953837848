import React from "react";
import "../styles/components/feature-3.scss";
// import { Insights } from "./Insights";
// import Config from "../Config";
// import { appendParamsFromCurrentURL } from "../utils/helpers";
// import ReactGA from "react-ga4";
// import { GA_EVENTS } from "../utils/constants";
import { PAGE_TYPES } from "../utils/pages-content";

export function Feature3({ pageData }) {
  return (
    <div className="feature-3">
      <div className="feature-3__body">
        <div className="feature-3__picture">
          <img src={pageData.feature3Image} alt="" />
        </div>
        <div className="feature__content feature-3__content">
          {pageData.type === PAGE_TYPES.CAFE ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">
                  <span className="feature__content__text feature__content__text--2">Boost</span> Morning Pastry
                </p>
                <p className="feature__content__text feature__content__text--2">Sales!</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Encourage customers to grab a bite with their coffee using{" "}
                  <span className="feature__content__text-2--bold">smart insights</span> and{" "}
                  <span className="feature__content__text-2--bold">targeted campaigns</span>:
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "Pair your morning coffee with our freshly baked croissant and get 20% off —stop by today!"
                </p>
                <p className="feature__content__text-2">
                  Turn routine coffee runs into <span className="feature__content__text-2--bold">higher-value orders</span> with
                  gentle, irresistible nudges.
                </p>
              </div>
            </>
          ) : pageData.type === PAGE_TYPES.PET_GROOMING ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">Boost Vaccine</p>
                <p className="feature__content__text feature__content__text--2">Follow-Ups!</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Ensure pets stay healthy by reminding owners about{" "}
                  <span className="feature__content__text-2--bold">upcoming vaccinations:</span>
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "Simba’s vaccination is due next week. Book now to keep him healthy and protected!"
                </p>
                <p className="feature__content__text-2">
                  With <span className="feature__content__text-2--bold">automated reminders</span>, you’ll be ahead of the{" "}
                  <span className="feature__content__text-2--bold">competition</span> and keep clients{" "}
                  <span className="feature__content__text-2--bold">coming back.</span>
                </p>
              </div>
            </>
          ) : pageData.type === PAGE_TYPES.SALON ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">
                  Lock In <span className="feature__content__text feature__content__text--2">Regular</span> Haircut
                </p>
                <p className="feature__content__text feature__content__text--2">Appointments!</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Use <span className="feature__content__text-2--bold">smart insights</span> to remind clients it’s time for their
                  next trim with an <span className="feature__content__text-2--bold">automated message</span>:
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "Your last haircut was 5 weeks ago! Ready for a fresh look?"
                </p>
                <p className="feature__content__text-2">
                  Timely reminders keep clients on track and <span className="feature__content__text-2--bold">loyal</span> to your
                  salon.
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* <div className="feature__content__action-bar">
            <Insights
              text1="40% Increase"
              text2="In Customer Lifetime Value"
              imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-7.png"
              variant={2}
            />
            <a
              href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event(GA_EVENTS.TEST_IT_OUT.eventName);
              }}
              className="feature__content__action-bar__btn"
            >
              Test It Out!
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
