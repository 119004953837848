import React from "react";
import "../styles/components/insights-3.scss";

export const Insights3 = ({ text1, text2, imageURL }) => {
  return (
    <div className="insights-3">
      <img className="insights-3__img" src={imageURL} alt="" />
      <h2 className="insights-3__text-1">{text1}</h2>
      <p className="insights-3__text-2">{text2}</p>
    </div>
  );
};
