import React from "react";
import "../styles/components/feature-2.scss";
import { PAGE_TYPES } from "../utils/pages-content";
// import { Insights } from "./Insights";
// import Config from "../Config";
// import { appendParamsFromCurrentURL } from "../utils/helpers";
// import ReactGA from "react-ga4";
// import { GA_EVENTS } from "../utils/constants";

export function Feature2({ pageData }) {
  return (
    <div className="feature-2">
      <div className="feature-2__body">
        <div className="feature-2__picture">
          <img src={pageData.feature2Image} alt="" />
        </div>
        <div className="feature__content feature-2__content">
          {pageData.type === PAGE_TYPES.CAFE ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">
                  <span className="feature__content__text feature__content__text--2">Promote</span> New Seasonal
                </p>
                <p className="feature__content__text feature__content__text--1">Drinks</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Send out an <span className="feature__content__text-2--bold">enticing message</span> when you launch a new
                  seasonal menu:
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "Pumpkin Spice is back! Come try our new fall flavors before they’re gone!"
                </p>
                <p className="feature__content__text-2">
                  Increase <span className="feature__content__text-2--bold">foot traffic</span> by promoting limited-time
                  offerings with <span className="feature__content__text-2--bold">automated updates</span>.
                </p>
              </div>
            </>
          ) : pageData.type === PAGE_TYPES.PET_GROOMING ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">
                  Win Clients With <span className="feature__content__text feature__content__text--2">Unbeatable</span>
                </p>
                <p className="feature__content__text feature__content__text--2">Deals</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Remind clients of their next appointment and{" "}
                  <span className="feature__content__text-2--bold">offer discounts</span> on other services to{" "}
                  <span className="feature__content__text-2--bold">boost revenue:</span>
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "It’s time for Sandy’s deworming. Book your appointment now and snag 30% off on all grooming services."
                </p>
                <p className="feature__content__text-2">
                  Ensure pets stay healthy and owners stay proactive with{" "}
                  <span className="feature__content__text-2--bold">simple, automated campaigns.</span>
                </p>
              </div>
            </>
          ) : pageData.type === PAGE_TYPES.SALON ? (
            <>
              <div>
                <p className="feature__content__text feature__content__text--1">
                  Attract <span className="feature__content__text feature__content__text--2">Clients</span> With
                </p>
                <p className="feature__content__text feature__content__text--2">Irresistible Offers!</p>
              </div>

              <div className="feature__content-details">
                <p className="feature__content__text-2">
                  Use effortless, <span className="feature__content__text-2--bold">targeted campaigns</span> to attract clients
                  with deals and offers:
                </p>
                <p className="feature__content__text-2 feature__content__text-2--bold">
                  "Get your monthly facial and enjoy 20% off on your mani-pedi!"
                </p>
                <p className="feature__content__text-2">
                  A quick nudge keeps clients on a regular pampering schedule,{" "}
                  <span className="feature__content__text-2--bold">boosting repeat bookings</span> and{" "}
                  <span className="feature__content__text-2--bold">revenue.</span>
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
          {/* <div className="feature__content__action-bar">
            <Insights
              text1="32% Increase"
              text2="In Monthly Recurring Revenue"
              imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-6.png"
              variant={2}
            />
            <a
              href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                ReactGA.event(GA_EVENTS.GET_IT_NOW.eventName);
              }}
              className="feature__content__action-bar__btn"
            >
              Get It Now!
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}
