import React from "react";
import "../styles/components/registration-step-card.scss";

export const RegistrationStepCard = ({ text1, text2 }) => {
  return (
    <div className="registration-step-card">
      <img
        className="registration-step-card__icon"
        src="https://assets.boomingo.io/boomingo/website/images/app/full-stop-icon.svg"
        alt=""
      />
      <p className="registration-step-card__text-1">{text1}</p>
      <p className="registration-step-card__text-2">{text2}</p>
    </div>
  );
};
