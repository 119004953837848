import React from "react";
import "../styles/components/registration.scss";
import { RegistrationStepCard } from "./RegistrationStepCard";

export function Registration() {
  return (
    <section className="registration-section">
      <div className="registration">
        <div className="registration__heading-container">
          <p className="registration__heading-container__text-1">No App Required For Your Customers</p>
          {/* <p className="registration__heading-container__text-2">
            Business owners can sign up and launch mobile wallet engagement program in minutes.
          </p> */}
        </div>
        <div className="registration__steps">
          <RegistrationStepCard text1="Scan & Go" text2="Customers simply scan a QR code available at your café." />
          <RegistrationStepCard text1="Quick Register" text2="A few quick taps on their smartphone to register." />
          <RegistrationStepCard
            text1="Digital Stamp"
            text2="Receive a stamp on their loyalty card, kickstarting their rewards journey."
          />
        </div>
      </div>
    </section>
  );
}
