import React from "react";
import { PAGE_TYPES } from "../utils/pages-content";

function FeaturesHeading({ pageType }) {
  if (pageType === PAGE_TYPES.CAFE) {
    return (
      <p className="feature-1__heading">
        Your Edge In The Crowded World Of <span className="feature-1__heading--highlighted">Cafés</span>
      </p>
    );
  } else if (pageType === PAGE_TYPES.PET_GROOMING) {
    return (
      <p className="feature-1__heading">
        Your Edge In The Crowded World Of <span className="feature-1__heading--highlighted">Pet Grooming</span>
      </p>
    );
  } else if (pageType === PAGE_TYPES.SALON) {
    return (
      <p className="feature-1__heading">
        Your Edge In The Crowded World Of <span className="feature-1__heading--highlighted">Salons</span>
      </p>
    );
  }
}

export default FeaturesHeading;
