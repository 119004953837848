import React from "react";
import "../styles/components/landing-page-header.scss";
import { appendParamsFromCurrentURL } from "../utils/helpers";
import Config from "../Config";
import { GA_EVENTS } from "../utils/constants";
import ReactGA from "react-ga4";
import { Insights2 } from "./Insights2";

function LandingPageHeader() {
  return (
    <div id="header" className="landing-page-header">
      <div className="landing-page-header__content">
        <p className="landing-page-header__text--variant-1">Engage. Retain. Grow.</p>
        <h1 className="landing-page-header__text--variant-2">
          Boomingo: Your&nbsp;All&#8209;In&#8209;One
          <br />
          <span className="landing-page-header__text--variant-2 landing-page-header__text--variant-2--highlighted ">
            Mobile Marketing & Loyalty Solution
          </span>
        </h1>

        <p className="landing-page-header__text--variant-3">
          We are taking customer engagement to the next level. Ready to engage your audience, retain loyal customers and grow your
          business? We’ve got you covered.
        </p>
        <a
          href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event(GA_EVENTS.TRY_FOR_FREE.eventName);
          }}
          className="landing-page-header__btn"
        >
          Try For Free
        </a>
      </div>
      <div className="landing-page-header__image">
        <img src="https://assets.boomingo.io/boomingo/website/images/app/landing-page-hero-image.png" alt="" />
      </div>
      <div className="landing-page-header__insights">
        <Insights2
          text1="60%"
          text2="More Visibility"
          text3="60% of consumers prefer hearing from brands via text messaging at least once a week."
        />
        <Insights2
          text1="75%"
          text2="More Purchases"
          text3="75% of consumers say text messages frequently influence their purchasing decisions."
        />
        <Insights2
          text1="52%"
          text2="Easily Accessible"
          text3="52% of consumers now use mobile wallets to store loyalty cards, offers, coupons, gift cards, and tickets."
        />
        <Insights2
          text1="54%"
          text2="More Traffic"
          text3="Mobile wallets boost foot traffic: 54% make purchases, 60% use loyalty cards offline."
        />
      </div>
    </div>
  );
}

export default LandingPageHeader;
