import React from "react";
import "../styles/components/pricingV4.scss";
import { GA_EVENTS } from "../utils/constants";
import ReactGA from "react-ga4";
import Config from "../Config";
import { appendParamsFromCurrentURL } from "../utils/helpers";

export function Pricing() {
  return (
    <section id="pricing" className="pricing-v4">
      <div className="pricing-v4__text-container">
        <p className="pricing-v4__text--highlighted">Risk Free,</p>
        <p className="pricing-v4__text--primary">Quick To Start</p>
        <p className="pricing-v4__text--secondary">
          Sign up and launch your mobile wallet engagement program in minutes - all Risk Free, cancel anytime.
        </p>
        <p className="pricing-v4__text--secondary">{/* DUMMY FOR SPACING */}</p>
      </div>
      <div className="pricing-v4__cards">
        <div className={`pricing-v4__card pricing-v4__card`}>
          <div className="pricing-v4__card-content">
            <p className={`pricing-v4__card-description`}>{"Zero Obligations"}</p>
            <p className={`pricing-v4__card-description`}>{"No Credit Card Required"}</p>
          </div>

          <a
            href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
            target="_blank"
            rel="noopener noreferrer"
            className={`pricing-v4__card-btn pricing-v4__card-btn`}
            onClick={() => {
              ReactGA.event(GA_EVENTS.TRY_FOR_FREE.eventName);
            }}
          >
            {"TRY FOR FREE"}
          </a>
        </div>
      </div>
    </section>
  );
}
