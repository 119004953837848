import React, { useState } from "react";
import "../styles/components/feature-1.scss";
import "../styles/components/shared.scss";
// import { Insights } from "./Insights";
import { PopupModal } from "react-calendly";
import Config from "../Config";
import { PAGE_TYPES } from "../utils/pages-content";

export function Feature1({ pageData }) {
  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);

  return (
    <>
      <div id="feature-1" className="feature-1">
        {pageData.featuresHeading}
        <div className="feature-1__body">
          <div className="feature-1__picture">
            <img src={pageData.feature1Image} alt="" />
          </div>
          <div className="feature__content feature-1__content">
            {pageData.type === PAGE_TYPES.CAFE ? (
              <>
                <div>
                  <p className="feature__content__text feature__content__text--1">
                    Drive <span className="feature__content__text feature__content__text--2">Repeat</span> Coffee
                  </p>
                  <p className="feature__content__text feature__content__text--1">Orders</p>
                </div>

                <div className="feature__content-details">
                  <p className="feature__content__text-2">
                    <span className="feature__content__text-2--bold">Automatically remind</span> your old customers to swing by
                    for their favorite coffee:
                  </p>
                  <p className="feature__content__text-2 feature__content__text-2--bold">
                    "It’s been a week since your last coffee fix! Stop by for your usual brew today!"
                  </p>
                  <p className="feature__content__text-2">
                    Keep your café top of mind and <span className="feature__content__text-2--bold">boost customer loyalty</span>{" "}
                    with timely nudges.
                  </p>
                </div>
              </>
            ) : pageData.type === PAGE_TYPES.PET_GROOMING ? (
              <>
                <div>
                  <p className="feature__content__text feature__content__text--1">
                    Keep Pets On A <span className="feature__content__text feature__content__text--2">Grooming</span>
                  </p>
                  <p className="feature__content__text feature__content__text--2">Schedule!</p>
                </div>

                <div className="feature__content-details">
                  <p className="feature__content__text-2">
                    <span className="feature__content__text-2--bold">Automatically</span> remind pet owners when it’s time for
                    their next grooming session:
                  </p>
                  <p className="feature__content__text-2 feature__content__text-2--bold">
                    "It’s been 6 weeks since Fido’s last groom! Time for a fresh cut and clean?"
                  </p>
                  <p className="feature__content__text-2">
                    Timely <span className="feature__content__text-2--bold">reminders</span> keep pets looking their best and
                    owners <span className="feature__content__text-2--bold">loyal</span> to your grooming services.
                  </p>
                </div>
              </>
            ) : pageData.type === PAGE_TYPES.SALON ? (
              <>
                <div>
                  <p className="feature__content__text feature__content__text--1">
                    <span className="feature__content__text feature__content__text--2">Boost </span> <span>Facial</span>{" "}
                    <span className="feature__content__text feature__content__text--2">Rebookings</span>
                  </p>
                  <p className="feature__content__text feature__content__text--1">Effortlessly!</p>
                </div>

                <div className="feature__content-details">
                  <p className="feature__content__text-2">Follow up with facial clients 5 weeks after their appointment:</p>
                  <p className="feature__content__text-2 feature__content__text-2--bold">
                    "6 weeks since your last facial! Keep that glow going—book now!"
                  </p>
                  <p className="feature__content__text-2">
                    Keep clients <span className="feature__content__text-2--bold">coming back, boost</span> your{" "}
                    <span className="feature__content__text-2--bold">revenue</span> and keep your salon busy!
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}

            {/* <div className="feature__content__action-bar">
              <Insights
                text1="35% Increase"
                text2="In Customer Visits"
                imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-5.png"
                variant={2}
              />
              <button
                onClick={() => {
                  setIsCalendlyModalOpen(true);
                }}
                className="feature__content__action-bar__btn"
              >
                Book A Demo
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <PopupModal
        url={Config.CALENDLY_URL}
        text="Click here to schedule!"
        rootElement={document.getElementById("root")}
        onModalClose={() => setIsCalendlyModalOpen(false)}
        open={isCalendlyModalOpen}
        // pageSettings={{
        //   backgroundColor: "ffffff",
        //   hideEventTypeDetails: false,
        //   hideLandingPageDetails: false,
        //   primaryColor: "##2FCEAA",
        //   textColor: "#4d5055",
        // }}
      />
    </>
  );
}
