import React from "react";
import "../styles/components/dynamic-page-header.scss";
import { Insights } from "./Insights";
import { appendParamsFromCurrentURL } from "../utils/helpers";
import Config from "../Config";
import ReactGA from "react-ga4";
import { GA_EVENTS } from "../utils/constants";

export function DynamicPageHeader({ pageData }) {
  return (
    <header id="header" className="dynamic-page-header">
      <div className="dynamic-page-header__content">
        {pageData.headerText1}
        <p className="dynamic-page-header__text--variant-2">{pageData.headerText2}</p>
        {/* <p className="dynamic-page-header__text--variant-3">{pageData.headerText3}</p> */}
        <a
          href={appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE)}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => {
            ReactGA.event(GA_EVENTS.TRY_FOR_FREE.eventName);
          }}
          className="dynamic-page-header__btn"
        >
          Try For Free
        </a>
      </div>
      <div className="dynamic-page-header__image">
        <img src={pageData.bannerImage} alt="" />
      </div>
      <div className="dynamic-page-header__insights">
        <Insights
          text1="42% Increase"
          text2="In Revenue"
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-1.png"
          variant={1}
        />
        <Insights
          text1="23% Decrease"
          text2="Customer Acquisition Cost"
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-2.png"
          variant={1}
        />
        <Insights
          text1="67% More"
          text2="Spending By Members"
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-3.png"
          variant={1}
        />
        <Insights
          text1="20% Increase"
          text2="In ROI"
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-4.png"
          variant={1}
        />
      </div>
    </header>
  );
}
