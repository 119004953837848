import React from "react";
import "../styles/components/insights-section.scss";
import { Insights3 } from "./Insights3";

function InsightsSection() {
  return (
    <div id="insights-section" className="insights-section">
      <div className="insights-section__widgets">
        <Insights3
          text1="Engage Your Audience"
          text2="Put your brand in the spotlight with our mobile marketing wallet, offering personalized promotions, targeted messaging and rewards that hit their inbox just when they need it."
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-7.svg"
        />
        <Insights3
          text1="Retain Loyal Customers"
          text2="Turn one-time visitors into loyal regulars with instant rewards, special offers and loyalty programs designed to keep them coming back."
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-8.svg"
        />
        <Insights3
          text1="Personalize Loyalty Cards"
          text2="Pick from expert templates or customize with ease—ideal for cafés, salons and more. Simple setup, big results."
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-9.svg"
        />
        <Insights3
          text1="Discover Valuable Business Insights"
          text2="Access powerful data instantly and understand your customers' behaviors in real time, empowering you to make informed decisions that drive revenue growth."
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-10.svg"
        />
        <Insights3
          text1="Grow Your Business"
          text2="Watch your business thrive as repeat visits multiply and new faces walk through the door. Boomingo gives you the tools to grow your customer base and your revenue."
          imageURL="https://assets.boomingo.io/boomingo/website/images/insights/icon-11.svg"
        />
      </div>
      <div className="insights-section__image">
        <img src="https://assets.boomingo.io/boomingo/website/images/app/mobile-image.png" alt="" />
      </div>
    </div>
  );
}

export default InsightsSection;
