import Config from "../Config";
import { appendParamsFromCurrentURL } from "./helpers";
import { PAGE_TYPES, ROUTES } from "./pages-content";

export const GA_EVENTS = {
  PAGE_VISIT: {
    eventName: "cl:user-visits-page",
  },
  NAVBAR_FEATURES: {
    eventName: "cl:user-selects-on-navbar-features",
  },
  NAVBAR_PRICING: {
    eventName: "cl:user-selects-on-navbar-pricing",
  },
  LOGIN: {
    eventName: "cl:user-clicks-button-login",
  },
  SIGNUP: {
    eventName: "cl:user-clicks-button-sign-up",
  },
  // START_NOW: {
  //   eventName: "cl:user-clicks-button-start-now",
  // },
  TRY_FOR_FREE: {
    eventName: "cl:user-clicks-button-try-for-free",
  },
  // GET_IT_NOW: {
  //   eventName: "cl:user-clicks-button-get-it-now",
  // },
  // TEST_IT_OUT: {
  //   eventName: "cl:user-clicks-button-test-it-out",
  // },
  MONTHLY_PLAN_BUY_NOW: {
    eventName: "cl:user-clicks-button-monthly-plan-buy-now",
  },
  ANNUAL_PLAN_BUY_NOW: {
    eventName: "cl:user-clicks-button-annual-plan-buy-now",
  },
  // MONTHLY_PLAN_TRY_FOR_FREE: {
  //   eventName: "cl:user-clicks-button-monthly-plan-try-for-free",
  // },
  // ANNUAL_PLAN_TRY_FOR_FREE: {
  //   eventName: "cl:user-clicks-button-annual-plan-try-for-free",
  // },
  DOWNLOAD_IOS_APP: {
    eventName: "cl:user-clicks-button-download-ios-app",
  },
  DOWNLOAD_ANDROID_APP: {
    eventName: "cl:user-clicks-button-download-android-app",
  },
};

export const PLANS_V3 = {
  monthly: {
    id: 1,
    name: "Monthly",
    description: "Enroll More Than 10 Customers",
    pricePrimary: "USD $65/month",
    priceSecondary: null,
    buttonText: "Buy Now",
    redirectLink: appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE_AND_PAYMENT_REQUIRED),
    gaEventName: GA_EVENTS.MONTHLY_PLAN_BUY_NOW.eventName,
  },
  annual: {
    id: 2,
    name: "Annual",
    description: "Enroll More Than 10 Customers",
    pricePrimary: "USD $50/month",
    priceSecondary: "USD $600 Billed Yearly",
    buttonText: "Buy Now",
    promoText: "Popular: Save 23%",
    promoImage: "https://assets.boomingo.io/boomingo/website/images/app/promo-image.png",
    redirectLink: appendParamsFromCurrentURL(Config.BOOMINGO_APP_URL_WITH_CREATE_ACCOUNT_PAGE_AND_PAYMENT_REQUIRED),
    gaEventName: GA_EVENTS.ANNUAL_PLAN_BUY_NOW.eventName,
  },
};

export const SHOWCASE_CARDS = [
  {
    id: 1,
    image: "https://assets.boomingo.io/boomingo/website/images/showcase/showcase_cafe.png",
    text: "Cafes",
    redirectLink: ROUTES[PAGE_TYPES.CAFE],
  },
  {
    id: 2,
    image: "https://assets.boomingo.io/boomingo/website/images/showcase/showcase_salon.png",
    text: "Salons",
    redirectLink: ROUTES[PAGE_TYPES.SALON],
  },
  {
    id: 3,
    image: "https://assets.boomingo.io/boomingo/website/images/showcase/showcase_pet_grooming.png",
    text: "Pet Grooming",
    redirectLink: ROUTES[PAGE_TYPES.PET_GROOMING],
  },
  {
    id: 4,
    image: "https://assets.boomingo.io/boomingo/website/images/showcase/showcase_more_on_the_way.png",
    text: "More On The Way",
    redirectLink: null,
  },
];

export const SOCIAL_MEDIA_ICONS = [
  {
    platform: "Facebook",
    iconUrl: "https://assets.boomingo.io/boomingo/website/images/social-media-icons/facebook.svg",
    profileUrl: "https://www.facebook.com/boomingo.io",
  },
  {
    platform: "Instagram",
    iconUrl: "https://assets.boomingo.io/boomingo/website/images/social-media-icons/instagram.svg",
    profileUrl: "https://www.instagram.com/boomingo.io/",
  },
  {
    platform: "Linkedin",
    iconUrl: "https://assets.boomingo.io/boomingo/website/images/social-media-icons/linkedin.svg",
    profileUrl: "https://www.linkedin.com/showcase/boomingo/",
  },
];
