import React from "react";
import { PAGE_TYPES } from "../utils/pages-content";
import "../styles/base/typography.scss";

function HeaderText1({ pageType }) {
  if (pageType === PAGE_TYPES.CAFE) {
    return (
      <p className="dynamic-page-header__text--variant-1">
        <br className="block-after-lg" /> Perk Up Coffee{" "}
        <span className="dynamic-page-header__text--variant-1--highlighted">Sales</span> And <br className="block-after-lg" />{" "}
        <span className="dynamic-page-header__text--variant-1--highlighted">Loyalty</span> For Your Café
      </p>
    );
  } else if (pageType === PAGE_TYPES.PET_GROOMING) {
    return (
      <p className="dynamic-page-header__text--variant-1">
        Automated Pet <br className="block-after-lg" /> Grooming Reminders <br className="block-after-lg" /> That Boost{" "}
        <span className="dynamic-page-header__text--variant-1--highlighted">Client Loyalty</span>
      </p>
    );
  } else if (pageType === PAGE_TYPES.SALON) {
    return (
      <p className="dynamic-page-header__text--variant-1">
        Build Your Salon's <br className="block-after-lg" /> Tribe With{" "}
        <span className="dynamic-page-header__text--variant-1--highlighted">Mobile Wallet Marketing</span>
        <br className="block-after-lg" />
      </p>
    );
  }
}

export default HeaderText1;
