import React from "react";
import { DynamicPageHeader } from "../components/DynamicPageHeader";
import { Feature1 } from "../components/Feature1";
import { Feature2 } from "../components/Feature2";
import { Feature3 } from "../components/Feature3";
import { Registration } from "../components/Registration";
import { Pricing } from "../components/PricingV4";
import { AppDownload } from "../components/AppDownload";
import { Footer } from "../components/Footer";

function DynamicPage({ pageData }) {
  return (
    <main className="page-container">
      <DynamicPageHeader pageData={pageData} />
      <Feature1 pageData={pageData} />
      <Feature2 pageData={pageData} />
      <Feature3 pageData={pageData} />
      <Registration />
      <Pricing />
      <AppDownload />
      <Footer />
    </main>
  );
}

export default DynamicPage;
