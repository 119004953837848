import React from "react";
import { Registration } from "../components/Registration";
import { Pricing } from "../components/PricingV4";
import { AppDownload } from "../components/AppDownload";
import { Footer } from "../components/Footer";
import Showcase from "../components/Showcase";
import LandingPageHeader from "../components/LandingPageHeader";
import InsightsSection from "../components/InsightsSection";

function LandingPage({ pageData }) {
  return (
    <main className="page-container">
      <LandingPageHeader />
      <InsightsSection />
      <Registration />
      <Showcase />
      <Pricing />
      <AppDownload />
      <Footer />
    </main>
  );
}

export default LandingPage;
