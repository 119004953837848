import React from "react";
import "../styles/components/footer.scss";
import { SOCIAL_MEDIA_ICONS } from "../utils/constants";

export function Footer() {
  return (
    <footer className="footer">
      <div className="footer__row-1">
        <p className="footer__row-1__text-1">Boomingo</p>
        <div className="footer__row-1__social-links-container">
          {SOCIAL_MEDIA_ICONS?.map((icon, index) => (
            <a key={index} href={icon.profileUrl} target="_blank" rel="noopener noreferrer">
              <img className="social-link-icon" src={icon.iconUrl} alt={icon.platform} />
            </a>
          ))}
        </div>
      </div>
      <div className="footer__row-2">
        <p>
          <span className="footer__copyright-text footer__copyright-text--1">© All rights reserved.</span>
          <span className="footer__copyright-text footer__copyright-text--2">
            {" "}
            {window.location.host.slice(0, 1).toUpperCase() + window.location.host.slice(1)}
          </span>
        </p>
      </div>
    </footer>
  );
}
