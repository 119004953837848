import React from "react";
import "../styles/components/insights-2.scss";

export const Insights2 = ({ text1, text2, text3 }) => {
  return (
    <div className="insights-2">
      <div className="insights-2__heading-container">
        <p className="insights-2__text--variant-1">{text1}</p>
        <p className="insights-2__text--variant-2">{text2}</p>
      </div>

      <p className="insights-2__text--variant-3">{text3}</p>
    </div>
  );
};
