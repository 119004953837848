export function isStringEmpty(value) {
  if (value == null || typeof value !== "string") {
    return true;
  }

  return value.trim().length === 0;
}

export function appendParamsFromCurrentURL(_targetURL) {
  const targetURL = new URL(_targetURL);
  const targetURLParams = targetURL.searchParams;

  const currentURLParams = new URLSearchParams(window.location.search);

  currentURLParams.forEach((value, key) => {
    if (!targetURLParams.has(key)) {
      targetURLParams.append(key, value);
    }
  });

  return targetURL.toString();
}
