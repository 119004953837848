import React from "react";
import "../styles/components/showcase.scss";
import { SHOWCASE_CARDS } from "../utils/constants";
import { isStringEmpty } from "../utils/helpers";
import { Link } from "react-router-dom";

function Showcase() {
  return (
    <div className="showcase-section">
      <div className="showcase">
        <div className="showcase__heading">
          <p>
            One Platform, Infinite Loyalty <span className="showcase__heading--highlighted"> Opportunities</span>
          </p>
        </div>

        <div className="showcase__cards">
          {SHOWCASE_CARDS.map((card, index) => (
            <div key={index} className="showcase__card">
              <div className="showcase__card-image-container">
                <Link to={card.redirectLink}>
                  <img
                    className={`showcase__card-image ${
                      card.redirectLink == null ? "showcase__card-image--blend" : "showcase__card-image--clickable"
                    }`}
                    src={card.image}
                    alt=""
                    onClick={(event) => {
                      if (isStringEmpty(card.redirectLink)) {
                        event.preventDefault();
                        event.stopPropagation();
                      } else {
                        window.scrollTo({ top: 0 });
                      }
                    }}
                  />
                </Link>
              </div>
              <p className="showcase__card-text">{card.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Showcase;
