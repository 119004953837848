import React from "react";
import "../styles/components/app-download.scss";
import ReactGA from "react-ga4";
import Config from "../Config";
import { isStringEmpty } from "../utils/helpers.js";
import { GA_EVENTS } from "../utils/constants.js";

export function AppDownload() {
  function handleLinkClick(event, link) {
    if (isStringEmpty(link)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  return (
    <section className="app-download-section">
      <div className="app-download">
        <div className="app-download__content">
          <p className="app-download__content__text-1">
            Your <span className="app-download__content__text-1 app-download__content__text-1--with-bg"> Companion</span>
          </p>
          <p className="app-download__content__text-1">On The Go</p>
          <p className="app-download__content__text-2">
            After signing up, download our iPhone or Android app which complements, adding convenience and flexibility wherever
            you are.
          </p>
          <div className="download-app-btn-container">
            <a
              href={Config.APPLE_STORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => {
                ReactGA.event(GA_EVENTS.DOWNLOAD_IOS_APP.eventName);
                handleLinkClick(event, Config.APPLE_STORE_LINK);
              }}
            >
              <img
                className="download-app-btn-container__image"
                src="https://assets.boomingo.io/boomingo/website/images/app/appstore.svg"
                alt="AppStore"
              />
            </a>

            <a
              href={Config.GOOGLE_PLAY_STORE_LINK}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(event) => {
                ReactGA.event(GA_EVENTS.DOWNLOAD_ANDROID_APP.eventName);
                handleLinkClick(event, Config.GOOGLE_PLAY_STORE_LINK);
              }}
            >
              <img
                className="download-app-btn-container__image"
                src="https://assets.boomingo.io/boomingo/website/images/app/playstore.svg"
                alt="PlayStore"
              />
            </a>
          </div>
        </div>
        <div className="app-download__image">
          <img src="https://assets.boomingo.io/boomingo/website/images/app/app-download-image.png" alt="" />
        </div>
      </div>
    </section>
  );
}
